import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaTelegramPlane,
  FaFacebook,
} from "react-icons/fa";

import openseaIcon from "../images/icon/opensea.svg";
import mediumIcon from "../images/icon/med.svg";

const data = [
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/Acelle_NFT",
  },

 
  {
    icon: <FaTelegramPlane />,
    url: "https://t.me/acellenft",
  },
 
];

export default data;
