import checkIcon from "../../images/icon/roadmap_check.svg"
import spinnerIcon from "../../images/icon/roadmap_spiner.png"

const data = [
    {
        icon: checkIcon,
        title: "Phase 01",
        subtitle: "Q1: Foundation and Token Launch",
        text: "Establish the ACELLE NFT Token team. Conduct market research and competitor analysis. Develop the ACELLE NFT Token smart contract and launch the token. CEX/DEX Listing. Secure initial partnerships and collaborations. Create the ACELLE NFT Token website and community channels."
        
    }, 
    {
        icon: checkIcon,
        title: "Phase 02",
        subtitle: "50% Soldout",
        text: "Begin the development of the ACELLE NFT marketplace. Launch the artist showcase platform for creators to list their NFTs. Integrate the ACELLE NFT Token into the platform for minting and transactions. Develop a user-friendly interface for both creators and collectors."
    }, 
    {
        icon: checkIcon,
        title: "Phase 03",
        subtitle: "Metaverse Launch",
        text: "Start a marketing campaign to attract artists and collectors to the platform. Foster a community of enthusiasts, content creators, and collectors. Initiate a referral program to encourage user growth. Begin planning for cross-promotions with artists and influencers."
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 04",
        subtitle: "70% Soldout",
        text: "Implement advanced features in the ACELLE NFT marketplace such as auction-style listings and additional content types. Optimize the marketplace's performance and scalability. Conduct stress tests to ensure stability during high transaction volumes."
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 05",
        subtitle: "80% Soldout", 
        text: "Explore DeFi protocols and integrations to offer yield farming and liquidity provision opportunities for ANT holders.  Initiate collaborations with lending and borrowing platforms to expand utility."
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 06",
        subtitle: "90% Soldout", 
        text: "Keep innovating and expanding the ACELLE NFT Token ecosystem based on user feedback and market trends. Foster a sustainable and thriving community of artists, collectors, and enthusiasts."
    }, 
]

export default data;