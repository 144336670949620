import SectionTitle from "../../../common/sectionTitle";
import data from "../../../assets/data/partnerData";
import PartnerStyledWrapper from "./Partner.style";

const Partner = () => {
  return (
    <PartnerStyledWrapper>
      <div className="our_partners_sect">
        <div className="container">
          
          
        </div>
      </div>
    </PartnerStyledWrapper>
  );
};

export default Partner;
