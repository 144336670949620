import aboutCardIcon1 from "../../images/icon/aboutus_card_icon1.svg"
import aboutCardIcon2 from "../../images/icon/aboutus_card_icon2.svg"
import aboutCardIcon3 from "../../images/icon/aboutus_card_icon3.svg"

 const data = {
    aboutDescription1: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur cites of the word in classical literature.',
    aboutDescription2: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original a latin professor at Hampden-Sydney',
    aboutInfo: [
        {
            icon: aboutCardIcon1,
            title: "Set up your wallet",
            text: "Install the MetaMask extension, create a secure wallet, and unlock the gateway to the exciting world of NFTs and decentralized applications (DApps). Get started on your digital asset journey today!"
        },
        {
            icon: aboutCardIcon2,
            title: "Add your NFTs",
            text: 'Take the next step in your NFT journey by uploading your digital assets to our platform. Showcase your unique NFT collection and join our vibrant community of creators, collectors, and enthusiasts.'
        },
        {
            icon: aboutCardIcon3,
            title: "Sell your NFT's",
            text: 'Monetize your digital art and collectibles by listing them for sale on our platform. Join the NFT marketplace, connect with buyers, and turn your creations into a source of income.'
        }
    ]
}

export default data;